<template>
<div class="about">
    <a href="/" title="Home" class="home"><font-awesome-icon icon="arrow-left" /></a>
    <article>
        <h1 class="intro">Welcome to the free ES-LCP benefit check website</h1>
        <h2>About this tool</h2>
        <p>The means-tested benefits tool on this site has been developed as a joint venture between Lane Clark & Peacock (LCP) and Engage Smarter (ES).</p>
        <p>It was developed to demonstrate that consumers looking to withdraw money from their pension need simple and relevant insights to help them understand the implications for them.</p>
        <h2>Embedding this tool</h2>
        <p>Our standard rules for embedding this tool are governed by our <a href="javascript:void(0)" @click="showTerms = true">terms and conditions</a>.</p>
        <h2>Customising this tool for your own customers</h2>
        <p>If you wish to have a customised version of this tool embedded into your website, apps, chatbot or internal systems, then this can be done quickly and cost-effectively.</p>
        <p>Get in contact with us to discuss your requirements.</p>
        <ul>
            <li>LCP – <a href="mailto:steve.webb@lcp.uk.com">steve.webb@lcp.uk.com</a></li>
            <li>ES - <a href="mailto:info@engagesmarter.co">info@engagesmarter.co</a></li>
        </ul>
        <h2>Experts</h2>
        <p>If you are an expert on pensions or benefits provision and you see areas where you think the tool is missing something important or could be improved, please do get in contact to provide the feedback.</p>
        <p>This is much appreciated and we can together help keep this as an up-to-date useful free tool for consumers.</p>
        <h2>About Lane Clark Peacock</h2>
        <p>Lane Clark Peacock is an independent partnership providing actuarial consultancy services.</p>
        <p>More information - <a href="https://www.lcp.uk.com/about-us/" target="_blank">About us | Lane Clark & Peacock LLP (lcp.uk.com)</a></p>
        <p>Contact – <a href="mailto:steve.webb@lcp.uk.com">steve.webb@lcp.uk.com</a></p>
        <h2>About Engage Smarter</h2>
        <p>Engage Smarter is a technology and services business focused on creating more engaging customer journeys for consumers, particularly in financial services.</p>
        <p>More information - <a href="https://www.engagesmarter.co" target="_blank">https://www.engagesmarter.co</a></p>
        <p>Contact - <a href="mailto:info@engagesmarter.co">info@engagesmarter.co</a></p>
    </article>
    <div class="modal" v-if="showTerms">
        <div class="modal-content">
            <terms/>
        </div>
        <button @click="showTerms = false">
            OK <font-awesome-icon icon="times" />
        </button>
    </div>
</div>
</template>

<script>
import Terms from '@/components/terms.vue';

export default {
    components: { Terms },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data () {
        return {
            showTerms: false,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '@/scss/colours.scss';
.about {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: left;
    overflow: auto;
    padding: 1rem;
}

button {
    background: $lcpBlue;
    border: 0;
    padding: 1rem 2rem;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 2rem;
    box-shadow: rgba(39, 29, 29, 0.1) 2px 2px 6px;
    align-self: flex-end;
    cursor: pointer;
    transition: transform 0.5s;
    margin-top: 4rem;
    margin-right: 1rem;
    &:hover {
        transform: scale(1.05) translateZ(0);
    }
    svg {
        margin-left: 0.5rem;
    }
    &.start {
        align-self: center;
        margin-top: 2rem;
    }
    @media (max-width: 768px) {
        align-self: center;
    }
    &.disabled, &:disabled {
        pointer-events: none;
        background: #aaa;
    }
    &.watermelon {
        background: $lcpWatermelon;
    }
    &.find-out-more {
        background: $lcpWatermelon;
        align-self: center;
        svg {
            margin-right: 0.5rem;
        }
    }
}

article {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

a {
    color: $lcpDarkBlue;
    text-decoration: underline;
}

h1 {
    font-family: 'DTL Documenta', 'Georgia';
    font-style: italic;
    font-size: 2rem;
    color: $lcpBlue;
    padding: 1.5rem 0.5rem;
    width: 100%;
    margin-bottom: 2rem;
}

.home {
    position: fixed;
    font-size: 2.5rem;
    top: 2.2rem;
    left: 2rem;
    @media (max-width: 768px) {
    top: 1.2rem;
    }
}

h2 {
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    padding: 0.5rem;
    line-height: 1.5;
}

p {
    line-height: 1.5;
    font-size: 1.25rem;
    padding: 0.5rem;
}

ul {
    padding: 0.5rem 2rem;
    list-style: square;
    li {
        line-height: 1.5;
        font-size: 1.25rem;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 5rem 1rem;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    @media (max-width: 800px) {
        padding: 1rem 1rem;
    }
    .modal-content {
        background: #fff;
        max-width: 1200px;
        padding: 1rem;
        overflow: auto;
        border-radius: 0.25rem;
        text-align: left;
        width: 100%;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 8px;
        border-left: 1rem solid #eee;
        a {
            white-space: pre-wrap;
        }
    }
    button {
        position: relative;
        align-self: center;
        margin-top: 1rem;
    }

    p {
        padding: 0.5rem 1rem;
    }
}

</style>
