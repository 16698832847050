import Vue from 'vue';
import DynamiUI from '@lcp/dynamic-ui-typescript';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faCheck, faTimes, faArrowRight, faPoundSign, faArrowLeft, faDownload,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(DynamiUI);
library.add(faCheck, faTimes, faArrowRight, faPoundSign, faDownload, faArrowLeft);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
