



























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Interview from '@/models/interview';
import Question from '@/models/question';
import Schema from '@/models/schema';
import QuestionFlow from '@/models/questionFlow';
import Terms from '@/components/terms.vue';
import Gauge from '@/components/gauge.vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import html2pdf from 'html2pdf.js';
import interviewApi from '../api/interviewApi';

@Component({
    components: { Terms, Gauge },
})
export default class Home extends Vue {
    questionData: Interview | null = null;

    allQuestionData: Schema | null = null;

    responses: Record<string, unknown> = {}

    answeredQuestions: Array<Question> = [];

    answeredFlowQuestions: Array<QuestionFlow> = [];

    hasStarted = false;

    currentQuestion: Question | null = null;

    acceptedGuidance = true;

    acceptedPrivacy = false;

    questionId = 'EligibilityForMeansTestedBenefits';

    questionFlowId = 'EligibilityForMeansTestedBenefits';

    pensionScheme = '';

    target = '';

    otherPensionScheme = '';

    hasAnsweredPensionScheme = true;

    loading = false;

    answeredCount = 0;

    showTerms = false;

    whatNow = false;

    printing = false;

    frontEndId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = Math.random() * 16 | 0;
        // eslint-disable-next-line
        const v = (c === 'x') ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });

    printOptions = {
        filename: 'es-lcp-benefit-check-results',
        margin: 16,
        image: {
            type: 'jpeg',
            quality: 0.98,
        },
        html2canvas: {
            dpi: 1200,
            width: 1440,
            scale: 4,
            letterRendering: true,
            useCORS: true,
            onclone: (doc: Document): void => {
                const svgElements = doc.body.querySelectorAll('svg.svg-inline--fa');
                svgElements.forEach((item) => {
                    item.setAttribute('width', item.getBoundingClientRect().width.toString());
                    item.setAttribute('height', item.getBoundingClientRect().height.toString());
                    (item as HTMLElement).style.width = '';
                    (item as HTMLElement).style.height = '';
                });
                const breaks = doc.body.querySelectorAll('.break');
                breaks.forEach((item) => {
                    (item as HTMLElement).style.borderBottom = 'none';
                });
            },
            onrendered (canvas: HTMLCanvasElement): void {
                const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                ctx.imageSmoothingEnabled = true;
            },
        },
        jsPDF: {
            unit: 'px',
            format: 'letter',
            orientation: 'portrait',
        },

    };

    pensionSchemes = [
        'Aegon',
        'Aviva',
        'Fidelity',
        'Legal & General (L&G)',
        'LV=',
        'NEST',
        'Now: Pensions',
        'The People’s Pension (B&CE)',
        'Phoenix',
        'Prudential',
        'Royal London',
        'Scottish Widows',
        'Smart Pension',
        'Standard Life',
        'Other [please enter]',
    ]

    questionFlows: Array<QuestionFlow> = [
        {
            title: 'Receiving means-tested benefits',
            question: 'Are you currently receiving any means-tested benefits?',
            type: 'radio',
            options: [{ id: true, name: 'Yes' }, { id: false, name: 'No' }],
            id: 'EligibilityForMeansTestedBenefits',
            endScreen: {
                false: '_needBenefits',
                true: 'MeansTestedBenefitsApplying',
            },
        },
        {
            title: 'Receiving benefits type',
            question: 'Which of these means-tested benefits are you receiving?',
            type: 'radio',
            options: [{ id: 'Pension Credit', name: 'Pension Credit' }, { id: 'Universal Credit', name: 'Universal Credit (or other working age benefits such as tax credits or income-based Employment Support Allowance)' }],
            endScreen: {
                'Pension Credit': 'PensionCreditPerWeek',
                'Universal Credit': '_WillUniveralCreditReduce',
            },
            id: 'MeansTestedBenefitsApplying',
        },
        {
            title: 'Pension Credit payment per week',
            question: 'How much Pension Credit do you receive each week?',
            type: 'number',
            next: '_WillPensionCreditReduce',
            id: 'PensionCreditPerWeek',
            exclusiveMaximum: 10000,
            minimum: 0,
        },
        {
            type: 'api',
            target: 'WillPensionCreditChange',
            id: '_WillPensionCreditReduce',
            initialData: {
                EligibilityForPensionCredit: true,
                EligibilityForUniversalCredit: false,
            },
            endScreen: {
                1: '_pcChange',
                0: '_pcNoImpact',
            },
        },
        {
            type: 'api',
            target: 'WillUniversalCreditReduce',
            id: '_WillUniveralCreditReduce',
            initialData: {
                EligibilityForUniversalCredit: true,
                EligibilityForPensionCredit: false,
            },
            endScreen: {
                1: '_ucChange',
                0: '_ucNoImpact',
            },
        },
        {
            type: 'end',
            id: '_ucChange',
            next: '_ChangeInUniversalCreditPerMonth',
        },
        {
            type: 'end',
            id: '_ucNoImpact',
        },
        {
            type: 'end',
            id: '_pcChange',
            next: '_ChangeInPensionCreditPerWeek',
        },
        {
            type: 'end',
            id: '_pcNoImpact',
        },
        {
            type: 'end',
            id: '_needBenefits',
        },
        {
            id: '_ChangeInPensionCreditPerWeek',
            type: 'api',
            target: 'ChangeInPensionCreditPerWeek',
            next: '_pcChangeResult',
        },
        {
            id: '_ChangeInUniversalCreditPerMonth',
            type: 'api',
            target: 'ChangeInUniversalCreditPerMonth',
            next: '_ucChangeResult',
        },
        {
            type: 'end',
            id: '_pcChangeResult',
            getRationale: 'RationaleForPCChange',
        },
        {
            type: 'end',
            id: '_ucChangeResult',
            getRationale: 'RationaleForUCChange',

        },
    ]

    rationale = '';

    get formattedRationale (): string {
        const regex = /\n/g;
        return this.rationale.replace('\n', '').replace(regex, '<br />');
    }

    get creditAmount (): number {
        return this.apiResponses.PensionCreditPerWeek as number ?? this.apiResponses.UniversalCreditPerMonth ?? 0;
    }

    get currentFlowQuestion (): QuestionFlow | null {
        return this.questionFlows.find((a) => a.id === this.questionFlowId) || null;
    }

    get questionsToPrint (): Array<unknown> {
        return [...this.answeredFlowQuestions.filter((a) => a.type !== 'end'), ...this.answeredQuestions].map((a) => ({
            title: a.title,
            question: a.question,
            type: a.type,
            id: a.id,
            response: this.responses[a.id] || '',
        }));
    }

    get questionToShow (): QuestionFlow | Question | null {
        if (!this.currentFlowQuestion) return null;
        if (this.currentFlowQuestion.type === 'api') {
            return this.currentQuestion;
        }
        return this.currentFlowQuestion;
    }

    get questions (): Array<Question & {id: string }> {
        return Object.keys(this.allQuestionData!.properties).map((id) => ({
            ...this.allQuestionData!.properties[id],
            id,
        }));
    }

    get benefitType (): string {
        return this.apiResponses.MeansTestedBenefitsApplying as string;
    }

    get canSubmit (): boolean {
        return this.currentQuestion?.type === 'number' || this.responses[this.questionId] !== null;
    }

    get upcomingQuestions (): Array<Question> {
        if (!this.questionData?.schema?.properties) return [];
        return Object.values(this.questionData!.schema.properties)
            .filter((a) => !this.answeredQuestions.find((b) => b.title === a.title));
    }

    get allQuestions (): Array<Question> {
        if (!this.allQuestionData?.properties) return [];
        return Object.values(this.allQuestionData.properties);
    }

    get remainingQuestions (): Array<Question> {
        const remaining = ((100 - this.progress) / 100) * (this.answeredQuestions.length + 1);
        return this.allQuestions
            .filter((a, i) => !this.upcomingQuestions.find((b) => b.title === a.title)
            && !this.answeredQuestions.find((b) => b.title === a.title)
            && i < (remaining - (this.loading ? 1 : 0)));
    }

    get pageTitle (): string {
        return 'Welcome to the free ES-LCP benefit check website';
    }

    get progress (): number {
        return this.questionData?.progress ?? 0;
    }

    get radioOptions (): Array<{ id: unknown, name: string }> {
        if (this.currentFlowQuestion?.type !== 'api') return (this.questionToShow as QuestionFlow).options || [];
        if (!this.currentQuestion) return [];
        const enumOptions: Array<string> = [];
        if (this.currentQuestion.type === 'boolean') return [{ id: true, name: 'Yes' }, { id: false, name: 'No' }];
        if (this.currentQuestion.allOf?.length) {
            this.currentQuestion.allOf.forEach((ref) => {
                const enumId = ref.$ref.split('/')[ref.$ref.split('/').length - 1];
                enumOptions.push(...this.currentQuestion!.schema!.definitions![enumId].enum!);
            });
        }
        return enumOptions.map((a) => ({ id: a, name: this.uppercase(a) }));
    }

    get apiResponses (): Record<string, unknown> {
        const obj: Record<string, unknown> = {};
        Object.keys(this.responses).forEach(((a) => {
            if (a[0] === '_') return;
            obj[a] = this.responses[a];
        }));
        return obj;
    }

    answeredPensionScheme (): void {
        this.hasAnsweredPensionScheme = true;
        Vue.set(this.responses, 'pensionScheme', this.pensionScheme);
        Vue.set(this.responses, 'otherPensionScheme', this.otherPensionScheme);
        this.logData();
    }

    logData (endData: Record<string, string> = {}): void {
        interviewApi.logData(this.frontEndId, {
            ...this.responses,
            ...endData,
        });
    }

    uppercase (val: string): string {
        if (!val) return '';
        return val.toString()[0].toUpperCase() + val.toString().substring(1);
    }

    async gotoQuestion (question: Question): Promise<void> {
        this.currentQuestion = question;
        this.questionId = question.id;
        const index = this.answeredQuestions.indexOf(question);
        this.answeredQuestions.splice(index);
        const keys = Object.keys(this.responses);
        for (let i = keys.length - 1; i >= 0; i -= 1) {
            if (!this.questionFlows.find((a) => a.id === keys[i])) {
                if (i >= index) delete this.responses[keys[i]];
            }
        }
        this.loading = true;
        this.questionData = await interviewApi.postQuestion(this.target, this.apiResponses);
        this.loading = false;
        this.currentQuestion = Object.values(this.questionData!.schema.properties)[0];
        this.currentQuestion.id = Object.keys(this.questionData!.schema.properties)[0];
        if (this.questionData.next?.length) this.questionId = this.questionData.next[0];
        this.currentQuestion.schema = this.questionData.schema;
    }

    get chance (): string {
        if (!this.questionData) return '';
        const val = this.questionData[this.target]!.histogram[1][1];
        if (Number(val) >= 0.5) this.questionData[this.target]!.histogram[1][1] = 1;
        if (Number(val) < 0.5) this.questionData[this.target]!.histogram[1][1] = 0;
        return this.questionData[this.target]!.histogram[1][1].toLocaleString();
    }

    get changeAmount (): number | null {
        if (!this.questionData) return null;
        return this.questionData[this.target]!.numerical?.mean ?? null;
    }

    get formattedChangeAmount (): string {
        const number = Math.abs(this.changeAmount || 0).toLocaleString() ?? '0';
        const arr = number.split('.');
        return arr[0];
        // if (arr.length === 1) return `${number}.00`;
        // if (arr[1].length === 1) return `${number}0`;
        // if (arr[1].length === 3) return number.substring(0, number.length - 1);
        // return number;
    }

    mounted (): void {
        if (this.$route?.query?.referrer) {
            this.responses._referrer = this.$route.query.referrer;
        }
        this.logData();
    }

    formatCurrency (val: number): string {
        const number = Math.abs(val || 0).toLocaleString() ?? '0';
        const arr = number.split('.');
        if (arr.length === 1) return `£${number}.00`;
        if (arr[1].length === 1) return `£${number}0`;
        if (arr[1].length === 3) return `£${number.substring(0, number.length - 1)}`;
        return `£${number}`;
    }

    async getRationale (target: string): Promise<void> {
        this.rationale = '';
        this.rationale = (await interviewApi.postQuestion(target, this.apiResponses))[target].categorical?.top ?? '';
    }

    async next (pushQuestion = true): Promise<void> {
        this.logData();
        const flowQuestion = this.questionFlows.find((a) => a.id === this.questionFlowId);
        if (!flowQuestion) return;
        if (flowQuestion.type !== 'api') {
            if (this.currentFlowQuestion && pushQuestion) this.answeredFlowQuestions.push(this.currentFlowQuestion);
            const response = this.responses[this.questionId];
            if (flowQuestion.endScreen && flowQuestion.endScreen[response as string]) {
                this.questionFlowId = flowQuestion.endScreen[response as string];
                this.questionId = this.questionFlowId;
                if (this.currentFlowQuestion?.getRationale) {
                    await this.getRationale(this.currentFlowQuestion?.getRationale);
                }
                if (!this.currentFlowQuestion || this.currentFlowQuestion.type === 'end') {
                    return;
                }
            } else {
                const nextQuestion = this.questionFlows.find((a) => a.id === this.currentFlowQuestion?.next);
                this.questionFlowId = nextQuestion!.id;
                this.questionId = this.questionFlowId;
                if (this.currentFlowQuestion?.getRationale) {
                    await this.getRationale(this.currentFlowQuestion?.getRationale);
                }
            }

            if (this.currentFlowQuestion!.type === 'api') {
                this.target = this.currentFlowQuestion!.target!;
                this.allQuestionData = await interviewApi.getAllQuestions(this.target);
                if (this.currentFlowQuestion?.initialData) {
                    this.responses = { ...this.responses, ...this.currentFlowQuestion.initialData };
                }
                this.next();
            }
            return;
        }
        if (this.currentQuestion && pushQuestion) this.answeredQuestions.push(this.currentQuestion);
        this.loading = true;
        this.questionData = await interviewApi.postQuestion(this.target, this.apiResponses);
        this.loading = false;
        if (!this.questionData.stop) {
            this.currentQuestion = Object.values(this.questionData!.schema.properties)[0];
            this.currentQuestion.id = Object.keys(this.questionData!.schema.properties)[0];
            this.currentQuestion.schema = this.questionData.schema;
            this.questionId = this.questionData!.next[0];
        } else {
            this.responses[this.questionFlowId] = this.changeAmount ?? this.chance;
            if (flowQuestion.endScreen && flowQuestion.endScreen[this.changeAmount ?? this.chance]) {
                this.questionFlowId = flowQuestion.endScreen[this.changeAmount ?? this.chance];
                Vue.set(this.responses, this.questionFlowId, this.changeAmount ?? this.chance);
                this.logData();
                if (this.currentFlowQuestion?.getRationale) {
                    await this.getRationale(this.currentFlowQuestion?.getRationale);
                }
                return;
            }
            const nextQuestion = this.questionFlows.find((a) => a.id === this.currentFlowQuestion?.next);
            this.questionFlowId = nextQuestion!.id;
            this.questionId = this.questionFlowId;
            if (nextQuestion?.getRationale) {
                Vue.set(this.responses, this.questionFlowId, this.changeAmount ?? this.chance);
                this.logData();
                await this.getRationale(nextQuestion?.getRationale);
            }
            this.currentQuestion = null;
        }
    }

    print (): void {
        const element = this.$refs.print;
        this.loading = true;
        window.setTimeout(async () => {
            html2pdf().from(element).set(this.printOptions).toPdf()
                .get('pdf')
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((pdf: any) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    // print current pdf width & height to console
                    for (let i = 1; i <= totalPages; i += 1) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.setFont('Montserrat', 'italic');
                        pdf.setTextColor(150);
                        // divided by 2 to go center
                        const date = new Date();
                        const dateText = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
                        pdf.text(dateText, pdf.internal.pageSize.getWidth() - 70,
                            pdf.internal.pageSize.getHeight() - 10);
                    }
                })
                .save();
            this.loading = false;
        }, 500);
    }
}
