import Interview from '@/models/interview';
import Schema from '@/models/schema';
import axios from 'axios';

// const apiUrl = process.env.NODE_ENV === 'production' ? 'https://otb5e76hbg.execute-api.eu-west-2.amazonaws.com/Prod/' : 'https://rq1pbmduo2.execute-api.eu-west-2.amazonaws.com/Prod/';
const apiUrl = 'https://otb5e76hbg.execute-api.eu-west-2.amazonaws.com/Prod/';
const logUrl = process.env.NODE_ENV === 'production' ? 'https://statepensionsurveyprod.azurewebsites.net/api/PensionsBenefitsLogger' : 'https://statepensionsurveydev.azurewebsites.net/api/PensionsBenefitsLogger';

export default class InterviewApi {
    static postQuestion (target: string, data: unknown): Promise<Interview> {
        return axios.post(`${apiUrl}interview/${target}?how_many=10&calculate=true&bin_count=1`, data).then((response) => response.data);
    }

    static getAllQuestions (target: string): Promise<Schema> {
        return axios.get(`${apiUrl}facts/${target}`).then((response) => response.data);
    }

    static logData (id: string, data: unknown): Promise<string> {
        return axios.post(logUrl,
            {
                frontEndId: id,
                data: JSON.stringify(data),
            });
    }
}
